import { useEffect, useRef } from 'react';
import Row from './row';
import { Train, SingleTrainRowSizes } from '../types';

interface RowProps {
  animate: boolean;

  nextTrains: Train[]|null;
  nextTrainIndex: number|null;
  nextForceArrow: boolean|null;

  prevTrains: Train[]|null;
  prevTrainIndex: number|null;
  prevForceArrow: boolean|null;

  isToggled: boolean;
  toggleCount: number,
  height: SingleTrainRowSizes;

  onAnimationComplete: ()=>void;
}

const RowAnimator: React.FC<RowProps> = ({
  animate, 
  nextTrains, nextTrainIndex, nextForceArrow,
  prevTrains, prevTrainIndex, prevForceArrow,
  isToggled,
  toggleCount,
  height,
  onAnimationComplete 
}) => {
  
  const boxRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const box = boxRef.current;

    if (!box) return;

    box.addEventListener('animationend', onAnimationComplete);

    // Cleanup the event listener
    return () => {
      box.removeEventListener('animationend', onAnimationComplete);
    };
  }, [onAnimationComplete]);

  const { prevRow, nextRow, type } = createRows(
    nextTrains,
    nextTrainIndex,
    nextForceArrow,
    prevTrains,
    prevTrainIndex,
    prevForceArrow,
    isToggled,
    toggleCount,
    height
  )
  const animateIndexForward = prevTrainIndex===null || nextTrainIndex===null || prevTrainIndex <= nextTrainIndex
  const indexSet = [prevRow]
  if(!animateIndexForward){
    for (let i = prevTrainIndex-1; i > nextTrainIndex; i--) {
      const row = makeIndexRow(i, height)
      indexSet.push(row)
    }
  }
  indexSet.push(nextRow)
  if(!animateIndexForward){
    indexSet.reverse()
  }


  const finalType = animate ? type : RowAnimationTypes.DEFAULT

  switch (finalType) {
    case RowAnimationTypes.ROLL_OUT:
      return (
        <div ref={boxRef} className={`beacons-row-animate ${finalType}`}>
          {prevRow}
        </div>
      );
    case RowAnimationTypes.ROLL_IN:
      return (
        <div ref={boxRef} className={`beacons-row-animate ${finalType}`}>
          {nextRow}
        </div>
      );
    case RowAnimationTypes.CHA_CHING:
      return (
        <div ref={boxRef} className={`beacons-row-animate ${finalType} ${!animateIndexForward?'reverse':''}`}>
           <div style={{"--total-rows": indexSet.length} as React.CSSProperties} className="front">{indexSet}</div>
           <div style={{"--total-rows": indexSet.length} as React.CSSProperties} className="back">{indexSet}</div>
        </div>
      );
    case RowAnimationTypes.DEFAULT:
    default:
      return nextRow
  }
}

export default RowAnimator;






enum RowAnimationTypes {
  ROLL_OUT='roll-out',
  ROLL_IN='roll-in',
  CHA_CHING='cha-ching',
  DEFAULT='default'
}


const createRows = (
  nextTrains: Train[]|null,
  nextTrainIndex: number|null,
  nextForceArrow: boolean|null,

  prevTrains: Train[]|null,
  prevTrainIndex: number|null,
  prevForceArrow: boolean|null,

  isToggled: boolean,
  toggleCount: number,

  height: SingleTrainRowSizes
) => {
  // Create the next row
  const haveNextData = nextTrainIndex !== null && nextTrains !== null && nextForceArrow !== null && nextTrains.length > nextTrainIndex
  const nextTrain = haveNextData ? nextTrains[nextTrainIndex] : undefined
  const nextRow = <Row key='next' forceArrow={nextForceArrow ?? false} isToggled={isToggled} toggleCount={toggleCount} train={nextTrain} height={height} />;

  // Create the prev row
  const havePrevData = prevTrainIndex !== null && prevTrains !== null && prevForceArrow !== null && prevTrains.length > prevTrainIndex
  const prevTrain = havePrevData ? prevTrains[prevTrainIndex] : undefined
  const prevRow = <Row key='prev' forceArrow={prevForceArrow ?? false} isToggled={isToggled} toggleCount={toggleCount} train={prevTrain} height={height} />

  if(prevTrain && !nextTrain){
    return {prevRow, nextRow: null, type:RowAnimationTypes.ROLL_OUT }
  }
  if(!prevTrain && nextTrain){
    return {prevRow: null, nextRow, type:RowAnimationTypes.ROLL_IN }
  }

  if(prevTrain){
    const isIndexSame = prevTrain?.index===nextTrain?.index
    const isBodySame = prevTrain?.interval===nextTrain?.interval && prevTrain?.route===nextTrain?.route 
      && prevTrain?.primary===nextTrain?.primary && prevTrain?.secondary===nextTrain?.secondary
      && prevForceArrow===nextForceArrow

    // Mostly the same, Enough matches to just update in place
    if(isBodySame && isIndexSame){
      return {prevRow: null, nextRow, type:RowAnimationTypes.DEFAULT }
    }

    // All Different, Full Animation
    return { prevRow, nextRow, type:RowAnimationTypes.CHA_CHING };
  }

  return {prevRow: null, nextRow, type:RowAnimationTypes.DEFAULT }
}

const makeIndexRow = (index: number, height: SingleTrainRowSizes)=>{
  const dummyTrain:Train = {
    index,//the only field that is shown
    route: '',
    primary: '',
    secondary: '',
    stop_status: '',
    delayed: false,
    interval: '',
    isArriving: false,
    est_minutes: 0
  }
  return <Row key={`dummy-${index}`} forceArrow={false} isToggled={false} toggleCount={0} train={dummyTrain} height={height} />
}