import { Train } from "../types";



export const shouldForceArrow=(trainList: Train[]): boolean =>
    trainList.some(train => typeof train.arrow === 'number')

export const calcIntervalEqual=(previouslyArriving:Train[],currentlyArriving:Train[])=>
    currentlyArriving.length === previouslyArriving.length &&
    currentlyArriving.every((current, index) => current.interval === previouslyArriving[index].interval);



export function isSafeToUpdate(currentList: Train[], newList: Train[], currentIndex: number): boolean {
    // possible for index to be negative
    if(currentIndex<0)
        return false;

    // Check if the currentIndex is out of bounds for either list
    if (currentIndex >= currentList.length || currentIndex >= newList.length) {
        return false;
    }

    // Check if all train intervals from index 0 to currentIndex are equal
    if (!currentList.slice(0, currentIndex + 1).every((train, i) => train.interval === newList[i].interval)) {
        return false;
      }

    // Check if the current index ETA in currentList is less than the next index ETA in newList
    const currentEta = currentList[currentIndex].est_minutes;
    const nextEtaInNewList = newList[currentIndex + 1]?.est_minutes;

    if (nextEtaInNewList !== undefined && currentEta >= nextEtaInNewList) {
        return false;
    }

    // If both checks pass, it is safe to update
    return true;
}
