import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { HttpStatus } from '../types';
import { retrieveTransferSize } from '../helpers/retrieveTransferSize';
import { buildHeliumUrl } from '../helpers/buildHeliumUrl';

const FetchTimeout = import.meta.env.VITE_API_FETCH_TIMEOUT ?? 5000;

const fetchHelium = async (nodeId: string): Promise<{ [key: string]: any }> => {
    const heliumUrl = buildHeliumUrl(nodeId);
    performance.clearResourceTimings()
    const startTime = performance.now();

    try {
        const response = await axios.get(heliumUrl, {
            timeout: FetchTimeout,
            headers: {'Accept':'application/json'},
            validateStatus: status => status === 200
        });

        const responseTime = Math.floor(performance.now() - startTime);
        const responseLength = await retrieveTransferSize(heliumUrl)

        // Log successful fetch
        if (import.meta.env.VITE_APP_LOG === "true") console.log(`Helium data fetched successfully - nodeId: ${nodeId}, statusCode: ${response.status}, responseTime: ${responseTime}, responseLength: ${responseLength}`);


        return { 
            data: response.data, 
            responseTime, 
            responseLength, 
            statusCode: response.status
        };
    } catch (error: any) {
        const responseTime = performance.now() - startTime;
        const responseLength = await retrieveTransferSize(heliumUrl)

        const status = error.response?.status ?? 0;
        const isTimeout = error.code === 'ECONNABORTED'
        // https://github.com/axios/axios/issues/838
        const isNetworkError = error.code === 'ERR_NETWORK'

        // Capture the error with Sentry and log to CloudWatch
        if(!isTimeout && !isNetworkError)
            Sentry.captureException(error);
        
        // Log successful fetch
        if (import.meta.env.VITE_APP_LOG === "true") 
            console.error(`Failed to fetch Helium data - nodeId: ${nodeId}, errorCode: ${error.code}, errorMessage: ${error.message}, statusCode: ${status}, responseTime: ${responseTime}, responseLength: ${responseLength}`);
        
        const derivedStatus = 
            isNetworkError ? HttpStatus.Network :
            isTimeout ? HttpStatus.Timeout :
            status > 0 ? (
                status>=200 && status<300 ? HttpStatus._2XX :
                status>=400 && status<500 ? HttpStatus._4XX :
                status>=500 && status<600 ? HttpStatus._5XX :
                HttpStatus.OtherCode
            ) : HttpStatus.No_Code

        throw new WrappedAxiosError(responseTime, responseLength, error, status, derivedStatus);
    }
}

export class WrappedAxiosError extends Error {
    responseTime: number;
    responseLength: number;
    statusCode: number;
    status: HttpStatus;

    constructor(responseTime: number, responseLength: number, error: Error, statusCode: number, status: HttpStatus) {
        super(error.message);
        this.name = 'WrappedAxiosError'; // Set a custom error name
        this.responseTime = responseTime;
        this.responseLength = responseLength;
        this.statusCode = statusCode;
        this.status = status;
    }
}

export default fetchHelium;