import React, { createContext, useContext, useState, ReactNode, useCallback, useRef, useEffect } from 'react';
import { ArrivingTrainBlink, ScreenInterval } from '../types';
import { useTimeContext } from './TimeContext';
import { useData } from './DataContext';

interface BlinkContextData {
  isToggled: boolean;
  getBlinkCount: (interval: string) => number;
}

const BlinkContext = createContext<BlinkContextData | undefined>(undefined);

export const BlinkProvider: React.FC<{ children: ReactNode; }> = ({ 
  children
}) => {
  const { trains: heliumTrains } = useData()
  const arrivingTrainBlinkCountsMap = useRef<Map<string, ArrivingTrainBlink>>(new Map());
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const { registerFunction, unregisterFunction } = useTimeContext();

  const getBlinkCount = useCallback((interval: string): number => {
    // Get current time for timestamp checking
    const currentTime = new Date();
  
    // Remove entries older than 1 hour
    const newMap = new Map(arrivingTrainBlinkCountsMap.current);
    newMap.forEach((value, key) => {
      const timeDifference = currentTime.getTime() - new Date(value.inserted).getTime();
      if (timeDifference > 60 * 60 * 1000) {
        newMap.delete(key);
      }
    })
    arrivingTrainBlinkCountsMap.current = newMap;
  
    // Retrieve or return placeholder initial blink count object
    const result = arrivingTrainBlinkCountsMap.current.get(interval) ?? { interval, blinkCount: 0, inserted: currentTime };
  
    return result.blinkCount;
  }, []);

  const incrementBlinkCount = useCallback((interval: string, shouldIncrement: boolean): number => {
    // Get current time for timestamp checking
    const currentTime = new Date();
  
    // Retrieve or create initial blink count object
    const result = arrivingTrainBlinkCountsMap.current.get(interval) ?? { interval, blinkCount: 0, inserted: currentTime };
  
    if (shouldIncrement) {
      // Increment the blink count
      const updatedResult = { ...result, blinkCount: result.blinkCount + 1 }; // Preserve original insertion time
      arrivingTrainBlinkCountsMap.current.set(interval, updatedResult);
      return updatedResult.blinkCount;
    }
  
    return result.blinkCount;
  }, []);

  const toggle = useCallback(() => {
    return new Promise<void>((resolve) => {
      setIsToggled(() => {
        // increment the blink
        if (heliumTrains.current) {
          heliumTrains.current.forEach((train) => {
            return incrementBlinkCount(train.interval, train.isArriving);
          });
        }

        // return toggle state
        return (new Date().getSeconds() % 2) === 1
      })
      resolve();
    });
  }, [incrementBlinkCount]);

  useEffect(() => {
    const toggleInterval = 1000;
    const toggleDelay = 1000;
    
    registerFunction(ScreenInterval.TOGGLE, toggle, toggleInterval, toggleDelay);
    return () => unregisterFunction(ScreenInterval.TOGGLE);
  }, [toggle, registerFunction, unregisterFunction]);

  return (
    <BlinkContext.Provider value={{ 
      isToggled, 
      getBlinkCount 
    }}>
      {children}
    </BlinkContext.Provider>
  );
};

export const useBlink = (): BlinkContextData => {
  const context = useContext(BlinkContext);
  if (!context) {
    throw new Error('useBlink must be used within a BlinkProvider');
  }
  return context;
}; 