
export const retrieveTransferSize = async (url: string): Promise<number> => {
    // Function to wait a specific amount of time
    const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    // Times to check the performance entry
    const checkIntervals = [25, 50, 100, 200, 400];
    for (let i = 0; i < checkIntervals.length; i++) {
        await wait(checkIntervals[i] - (i > 0 ? checkIntervals[i - 1] : 0)); // Wait for the specified interval, adjusting for the previous wait

        const entries = performance.getEntriesByName(url);
        if (entries.length > 0) {
            const lastEntry = entries[entries.length - 1]; // Get the latest entry for the URL
            // @ts-ignore: Property 'transferSize' does not exist on type 'PerformanceEntry'.ts(2339)
            const transferSize = lastEntry.transferSize ?? 0
            return transferSize; // Return the transferSize
        }
    }

    if (import.meta.env.VITE_APP_LOG === "true") console.error('No performance entry found for the request after all intervals.');
    return 0; // Return 0 if no entry is found
};