import React from 'react';
import { Icon } from './icon';

const SignNoRealtime: React.FC = () => {
  return (
    <div className="signNoRealtime">
      <div className='row-container'>
        <div>
          <Icon line={"CLOUD"} /> 
        </div>
        <div className='text-container'>
          Real-time arrival info unavailable
        </div>
      </div>
    </div>
  );
};

export default SignNoRealtime;