import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback, useRef, MutableRefObject } from 'react';
import { Train, SignMode, MetricName, HttpStatus, SignHeight, AnyMessageProps, ScreenInterval } from '../types';
import { useParams } from 'react-router-dom';
import fetchHelium from '../api/fetchHelium';
import { useCloudWatch } from './CloudwatchMetricContext';
import { StandardUnit } from '@aws-sdk/client-cloudwatch';
import * as Sentry from '@sentry/browser';
import { determineSignMode } from '../helpers/determineSignMode';
import { useTimeContext } from './TimeContext';


interface SignContextData {
  mode: SignMode,
  height: SignHeight,
  trains: MutableRefObject<Train[]>,
  messages: MutableRefObject<AnyMessageProps[]>
  updateContext: (data: any)=> void
  resetContext: ()=> void
}

const DataContext = createContext<SignContextData | undefined>(undefined);

export const DataProvider: React.FC<{ children: ReactNode; offline?: boolean }> = ({ children, offline }) => {
  const { addMetricRecord, setMRN } = useCloudWatch();
  const { id } = useParams();

  //initial sign state
  const trains = useRef<Train[]>([]);
  const messages = useRef<AnyMessageProps[]>([]);
  const [mode,setMode] = useState<SignMode>(SignMode.Loading);
  const [height,setHeight] = useState<SignHeight>(SignHeight.Unknown);


  const [consecutiveFailCount,setConsecutiveFailCount] = useState<number>(0);
  const lastGoodFetch = useRef<number>(-1);

  const updateContext=(data: any)=>{
    messages.current = data.messages || []
    trains.current = (data.sections?.[0]?.trains ?? []).map((train: Train, index:number) =>({
      ...train,
      index,
      isArriving: ['AT_STATION', 'ARRIVING'].includes(train.stop_status)
  }))

    const thisHeight = data.height===480 ? SignHeight.FULL : SignHeight.HALF
    setHeight(thisHeight)

    const hasError = !!data?.sections?.some((section: any) => section.error_status === 'NO_DATA');

    setMode(determineSignMode(messages.current, trains.current, thisHeight, hasError));
  }
  const resetContext=()=>{
    messages.current = []
    trains.current = []
    setHeight(SignHeight.Unknown)
    setMode(SignMode.Loading);
  }

  // Function to handle data fetching and updating the state
  const fetchData = useCallback(async () => {
    if (!id) return;

    try {
      const result = await fetchHelium(id);

      //update sign data refs
      updateContext(result.data)

      // Reset failure count and update the last good fetch timestamp
      setConsecutiveFailCount(0)
      const now = Date.now()
      lastGoodFetch.current = now

      // calculate timeSync
      const heliumTime = result.data?.timestamp*1000;
      const timeSync = heliumTime - now

      // Pass the MRN to the Metric Context
      setMRN(result.data?.canonical_mrn)

      // Log metrics to CloudWatch
      addMetricRecord({MetricName: MetricName.HttpResponseMilli, Value: result.responseTime, Unit: StandardUnit.Milliseconds, Timestamp: new Date()});
      addMetricRecord({MetricName: MetricName.HttpResponseKB, Value: result.responseLength, Unit: StandardUnit.Kilobytes, Timestamp: new Date()});
      addMetricRecord({MetricName: MetricName.HttpStatusCode, Value: result.statusCode, Unit: StandardUnit.None, Timestamp: new Date()});
      addMetricRecord({MetricName: MetricName.HttpStatus, Value: HttpStatus._2XX, Unit: StandardUnit.None, Timestamp: new Date()});
      addMetricRecord({MetricName: MetricName.TrainCount, Value:  trains.current.length, Unit: StandardUnit.Count, Timestamp: new Date()});
      addMetricRecord({MetricName: MetricName.StaleSeconds, Value: 0, Unit: StandardUnit.Seconds, Timestamp: new Date()});
      addMetricRecord({MetricName: MetricName.TimeSyncMilli, Value: timeSync, Unit: StandardUnit.Milliseconds, Timestamp: new Date()});

      addMetricRecord({MetricName: MetricName.PollSuccess, Value: 1, Unit: StandardUnit.Count, Timestamp: new Date()});


    } catch (error: any) {
      if(error.name !== 'WrappedAxiosError')
        Sentry.captureException(error);

      const staleSeconds = lastGoodFetch.current===-1 ? 0 :Math.floor( (Date.now()-lastGoodFetch.current)/1000 )

      setConsecutiveFailCount(prev => prev + 1)

      if(error?.responseTime) addMetricRecord({MetricName: MetricName.HttpResponseMilli, Value: error.responseTime, Unit: StandardUnit.Milliseconds, Timestamp: new Date()});
      if(error?.responseLength) addMetricRecord({MetricName: MetricName.HttpResponseKB, Value: error.responseLength, Unit: StandardUnit.Kilobytes, Timestamp: new Date()});
      if(error?.statusCode) addMetricRecord({MetricName: MetricName.HttpStatusCode, Value: error.statusCode, Unit: StandardUnit.None, Timestamp: new Date()});
      if(error?.status) {
        addMetricRecord({MetricName: MetricName.HttpStatus, Value: error.status, Unit: StandardUnit.None, Timestamp: new Date()});

        if(error.status===HttpStatus._2XX)
          addMetricRecord({MetricName: MetricName.HttpCode2XX, Value: 1, Unit: StandardUnit.Count, Timestamp: new Date()});
        else if(error.status===HttpStatus.Network)
          addMetricRecord({MetricName: MetricName.HttpErrorNetwork, Value: 1, Unit: StandardUnit.Count, Timestamp: new Date()});
        else if(error.status===HttpStatus.Timeout)
          addMetricRecord({MetricName: MetricName.HttpErrorTimeout, Value: 1, Unit: StandardUnit.Count, Timestamp: new Date()});
        else if(error.status===HttpStatus._4XX)
          addMetricRecord({MetricName: MetricName.HttpCode4XX, Value: 1, Unit: StandardUnit.Count, Timestamp: new Date()});
        else if(error.status===HttpStatus._5XX)
          addMetricRecord({MetricName: MetricName.HttpCode5XX, Value: 1, Unit: StandardUnit.Count, Timestamp: new Date()});
        else if(error.status===HttpStatus.OtherCode)
          addMetricRecord({MetricName: MetricName.HttpCodeOther, Value: 1, Unit: StandardUnit.Count, Timestamp: new Date()});
        else if(error.status===HttpStatus.No_Code)
          addMetricRecord({MetricName: MetricName.HttpErrorNoCode, Value: 1, Unit: StandardUnit.Count, Timestamp: new Date()});
      }

      addMetricRecord({MetricName: MetricName.PollFailure, Value: 1, Unit: StandardUnit.Count, Timestamp: new Date()});
      addMetricRecord({MetricName: MetricName.StaleSeconds, Value: staleSeconds, Unit: StandardUnit.Seconds, Timestamp: new Date()});
    }
  }, []);

  //On too many failures set the mode to Fallback
  useEffect(()=>{
    const fallbackThreshold = import.meta.env.VITE_SIGN_FETCH_FALLBACK_THRESHOLD || 4
    if(consecutiveFailCount>fallbackThreshold)
      setMode(SignMode.Fallback)
  },[consecutiveFailCount])

  
  const { registerFunction, unregisterFunction } = useTimeContext();
  // Start advancing when the component mounts
  useEffect(() => {
    if(offline) return
    const fetchApiInterval = Number(import.meta.env.VITE_API_FETCH_INTERVAL) || 5000;
    const fetchApiMaxDelay = Number(import.meta.env.VITE_API_FETCH_MAX_DELAY_DURATION) || 500;
    const fetchApiDelay = Math.floor(Math.random() * fetchApiMaxDelay)
    registerFunction(ScreenInterval.FETCH, fetchData, fetchApiInterval, 4000 + fetchApiDelay);
    return () => unregisterFunction(ScreenInterval.FETCH)
  }, [fetchData, offline, registerFunction, unregisterFunction]);  

  return (
    <DataContext.Provider value={{ mode, height, trains, messages, updateContext, resetContext }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = (): SignContextData => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};