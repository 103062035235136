import React from 'react';

const SignNoRecords: React.FC = () => {
  return (
    <div className="signNoRecords">
      <div>
        No upcoming trains at this location
      </div>
      <div className="font-weight-normal">
        Check <span className="font-url">mta.info</span> for service information
      </div>
    </div>
  );
};

export default SignNoRecords;