import React, { useCallback, useEffect, useState } from 'react';
import Message from './message';
import { AnyMessageProps, MessageRowSizes, ScreenInterval } from '../types';
import { useData } from '../contexts/DataContext';
import { useTimeContext } from '../contexts/TimeContext';

interface SignData {
  message: AnyMessageProps | undefined
}

const SignMessage: React.FC = () => {
  const { messages } = useData()
  const [signData, setSignData] = useState<SignData>({
    message: messages.current.find(m => m.type === 'FULL')
  })

  const { registerFunction, unregisterFunction } = useTimeContext();

  //rotate function
  const advance = useCallback(()=>{      
    return new Promise<void>((resolve) => {
      setSignData(() => ({
        message: messages.current.find(m => m.type === 'FULL')
      }))
      resolve();
    });
  },[])

  // Start advancing when the component mounts
  useEffect(() => {
    const rotationInterval = Number(import.meta.env.VITE_SCREEN_ROTATION_INTERVAL) || 5000;
    registerFunction(ScreenInterval.FULL_MESS_ADV, advance, rotationInterval);
    return () => unregisterFunction(ScreenInterval.FULL_MESS_ADV)
  }, [advance, registerFunction, unregisterFunction]);

  return <Message message={signData.message} height={MessageRowSizes.FULL}/>
};

export default SignMessage;