import { useParams } from 'react-router-dom';
import { useData } from '../contexts/DataContext';
import { MessageRowSizes, SignMode, SignModeComponentMap, SingleTrainRowSizes } from '../types';
import { useMetricBrowserStart } from '../hooks/useMetricBrowserStart';
import { useMetricModeChange } from '../hooks/useMetricModeChange';
import { useValidateHeight } from '../hooks/useValidateHeight';
import SignNoRealtime from '../components/SignNoRealtime';
import SignLoading from '../components/SignLoading';
import SignFullMessageTrains from '../components/SignFullMessageTrains';
import SignFullTrains from '../components/SignFullTrains';
import SignHalfTrains from '../components/SignHalfTrains';
import SignFullMessage from '../components/SignFullMessage';
import SignHalfMessage from '../components/SignHalfMessage';
import SolariWatchdog from '../components/SolariWatchdog';
import SignNoRecords from '../components/SignNoRecords';

const componentMap:SignModeComponentMap = {
  [SignMode.Loading]: <SignLoading/>,
  [SignMode.None]: <SignNoRecords/>,
  [SignMode.Fallback]: <SignNoRealtime/>,
  [SignMode.FullMessage]: <SignFullMessage/>,
  [SignMode.HalfMessage]: <SignHalfMessage onlyHalfMessages={false} height={MessageRowSizes.HALF}/>,
  [SignMode.JumboHalfMessage]: <SignHalfMessage onlyHalfMessages={true} height={MessageRowSizes.FULL}/>,
  [SignMode.HalfTrains]: <SignHalfTrains height={SingleTrainRowSizes.HALF}/>,
  [SignMode.FullMessageTrains]: <SignFullMessageTrains />,
  [SignMode.FullTrains]: <SignFullTrains />,
}

const Sign: React.FC = () => {
  const { mode, height } = useData()
  const { id } = useParams();
  
  useValidateHeight(height, id)
  useMetricBrowserStart();
  useMetricModeChange(mode);

  return <div className="sign">
    <SolariWatchdog mode={mode} />
    {componentMap[mode]}
  </div>

};

export default Sign;
